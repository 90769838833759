import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SelectBox } from 'devextreme-react/select-box'
import { useSnackbar } from 'notistack'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import SearchIcon from '@mui/icons-material/Search'
import utilServices from 'app/services/util-services'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Typography from '@mui/material/Typography'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import { Divider } from '@mui/material'

const TermGridFilter = ({ mutation, display }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [termSelection, setTermSelection] = useState('')

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const termYearNumber = useCallback((data) => {
        return data ? data.termYear + ' / ' + data.termNumber : ''
    }, [])

    const termLookupList = useCallback(async (searchTerm) => {
        try {
            return await termServices.getTermLookupList({ termYear: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupById = useCallback(async (key) => {
        try {
            return await termServices.getTermLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = () => {
        mutation.mutate({
            termYear: termSelection ? termSelection.termYear : null,
            termNumber: termSelection ? termSelection.termNumber : null,
        })
    }

    return (
        <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: display, borderRadius: 0 }}>
            <Divider />
            <Div
                className='grid-filter-parent'
                style={{ display: 'flex', margin: '0 1.5% 3.5% 3.5%', flexWrap: 'wrap' }}>
                <Div sx={{ mr: 3, mt: 1.5 }}>
                    <Div>
                        <Typography>Term</Typography>
                    </Div>
                    <Div sx={{ mt: 0.5 }}>
                        <SelectBox
                            style={{ width: 160 }}
                            dataSource={termLookupsStore}
                            itemTemplate={termYearNumber}
                            displayExpr={termYearNumber}
                            value={termSelection}
                            searchEnabled={true}
                            onValueChange={setTermSelection}
                            placeholder='All'
                            showClearButton={true}
                        />
                    </Div>
                </Div>
                <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                    <SearchIcon
                        size={'large'}
                        fontSize={'large'}
                        color={'success'}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        onClick={onFormSubmit}
                    />
                </Div>
            </Div>
        </JumboCardQuick>
    )
}

export default TermGridFilter
