import React, { useState, useCallback, useMemo } from 'react'
import { SelectBox } from 'devextreme-react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const PaymentsGridFilter = ({ mutation, display, filterIconColor }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [filter, setFilter] = useState({ customerId: null })

    let initialValues = {
        startDate: DateUtils.getStartDate(10).toISOString().slice(0, 10),
        endDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        customerId: '',
    }

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const toFullName = useCallback((data) => {
        return data ? data.firstName + ' ' + data.lastName : ''
    }, [])

    const customerLookupList = useCallback(async (searchTerm) => {
        try {
            return await customerServices.getCustomerLookupList({ firstName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const customerLookupById = useCallback(async (key) => {
        try {
            return await customerServices.getCustomerLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const customerLookupStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return customerLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = (startDate, endDate) => {
        mutation.mutate({
            startDate: startDate.toFilterStartDate(),
            endDate: endDate.toFilterEndDate(),
            ...filter,
        })
    }

    const onFilterSubmit = async (data, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit(data.startDate, data.endDate)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
        setFilter({ customerId: null })
    }

    const onCustomerSelectionChanged = (value) => {
        setFilter({ ...filter, customerId: value?.id })
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0 }}>
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='endDate' type='date' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={customerLookupStore}
                                        value={filter.customerId}
                                        itemTemplate={toFullName}
                                        displayExpr={toFullName}
                                        searchEnabled={true}
                                        onValueChange={onCustomerSelectionChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'outlined'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'outlined'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default PaymentsGridFilter
