import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DataSource from 'devextreme/data/data_source'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import CustomStore from 'devextreme/data/custom_store'
import { useSelector } from 'react-redux'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { useMediaQuery } from '@mui/material'

const UpdateClassForm = (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.families)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await customerLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return customerLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const customerEditorOptions = {
        dataSource: customerLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate(`/registrations/families`),
    }

    const validationRules = {
        familyName: [{ type: 'required', message: 'Family name is required.' }],
        customer: [{ type: 'required', message: 'Customer is required.' }],
        comment: [{ type: 'required', message: 'Comment is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Family
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateFamily)}>
                    <Form
                        mode='form'
                        formData={props.familyData}
                        showColonAfterLabel={true}
                        readOnly={true}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem captions='Update Family' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='familyName'
                                    editorOptions={{ readOnly: false }}
                                    validationRules={validationRules.familyName}
                                />
                                <SimpleItem
                                    dataField='customerId'
                                    editorType='dxSelectBox'
                                    validationRules={validationRules.customer}
                                    editorOptions={customerEditorOptions}>
                                    <Label text='Primary Contact' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='comments'
                                    editorOptions={{ readOnly: false }}
                                    editorType='dxTextArea'
                                    validationRules={validationRules.comment}
                                />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateClassForm
