import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Span from '@jumbo/shared/Span'
import { Grid } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PaidIcon from '@mui/icons-material/Paid'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import PaymentIcon from '@mui/icons-material/Payment'
import formatCurrency from '../../../../../../../utils/components/formatCurrency/formatCurrency'

const HeaderCard = ({ transaction }) => {
    return (
        <Grid>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    {transaction.txnNumber}
                                    <AccountBalanceWalletIcon
                                        color='common.white'
                                        sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }}
                                    />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'secondary.main',
                        }}>
                        <Typography fontFamily='Lobster, cursive' color='secondary.dark' variant={'h4'}>
                            <DateRangeIcon fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span sx={{ ml: 1 }}>{formattedDate(transaction.dateTxn)}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.2rem',
                                        },
                                    }}>
                                    Status <MoveUpIcon color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'success.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='success.dark'>
                            <CheckCircleOutlineIcon fontSize='large' sx={{ mt: -3, mb: -1, mr: 1 }} />
                            <Span sx={{ ml: 1 }}>{transaction.txnStatusName}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.1rem',
                                        },
                                    }}>
                                    {' '}
                                    Total Amount{' '}
                                    <LocalOfferIcon color='common.white' sx={{ fontSize: 27, mb: -0.5, mr: 1.5 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'warning.main',
                        }}>
                        <Typography variant={'h4'} fontFamily='Lobster, cursive' color='warning.dark'>
                            <Span sx={{ ml: 1 }}>{formatCurrency(transaction.totalAmount)}</Span>
                            <PaidIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                        </Typography>
                    </JumboCardQuick>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <JumboCardQuick
                        title={
                            <Stack
                                direction={'row'}
                                alignItems='center'
                                justifyContent='center'
                                spacing={2}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Typography
                                    fontFamily='Lobster, cursive'
                                    color='common.white'
                                    sx={{
                                        fontSize: {
                                            xs: '1.5rem',
                                            md: '1.1rem',
                                        },
                                    }}>
                                    Amount Payments <PaymentIcon color='common.white' sx={{ ml: 1 }} />
                                </Typography>
                            </Stack>
                        }
                        wrapperSx={{ textAlign: 'center' }}
                        headerSx={{
                            height: 40,
                            bgcolor: 'info.main',
                        }}>
                        <Typography color='info.dark' variant={'h4'} fontFamily='Lobster, cursive'>
                            <LocalAtmIcon fontSize='large' sx={{ mt: -3, mb: -1, ml: 1 }} />
                            <Span sx={{ ml: 1 }}>{formatCurrency(transaction.amountPaid)}</Span>
                        </Typography>
                    </JumboCardQuick>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HeaderCard
