import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const PaymentsGridData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { payments, loading, error, refreshing } = useSelector((state) => state.payments)
    const dateTimeColumns = ['datePayment', 'createTime', 'updateTime']
    const [sorting, setSorting] = useState([{ columnName: 'datePayment', direction: 'desc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => navigate('/registrations/payments', { state: { termId: row.termId } })}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.datePayment}
            </Link>
        )
    }

    const renderTransactionCell = (row) => {
        const transactions = row.linkedTransactions
        if (transactions && transactions.length > 0) {
            return transactions.map((transaction, index) => (
                <div key={index}>{`(${transaction.txnNumber}, ${formatCurrency(transaction.amountPaid)})`}</div>
            ))
        } else {
            return ''
        }
    }

    const fullNamecellRender = (data) => {
        return data.firstName + ' ' + data.lastName
    }

    const columns = [
        { name: 'datePayment', title: 'Payment Date' },
        { name: 'paymentAmount', title: 'Payment Amount', getCellValue: (row) => formatCurrency(row.paymentAmount) },
        { name: 'firstName', title: 'Customer', getCellValue: fullNamecellRender },
        { name: 'linkedTransactions', title: 'Transactions', getCellValue: renderTransactionCell },
        { name: 'comments', title: 'Comments' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'datePayment', width: min ? '130px' : '16%', minWidth: '130px', wordWrapEnabled: true },
            {
                columnName: 'paymentAmount',
                width: min ? '120px' : '14%',
                minWidth: '120px',
                wordWrapEnabled: true,
                align: 'right',
            },
            { columnName: 'firstName', width: min ? '150px' : '16%', minWidth: '150px', wordWrapEnabled: true },
            {
                columnName: 'linkedTransactions',
                width: min ? '170px' : '18%',
                minWidth: '170px',
                wordWrapEnabled: true,
            },
            { columnName: 'comments', width: min ? '290px' : '29%', minWidth: '290px', wordWrapEnabled: true },
            {
                columnName: 'Action',
                width: min ? '90px' : '7%',
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={payments.data} columns={columns}>
                    {payments.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <DateTimeTypeProvider for={dateTimeColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={payments.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default PaymentsGridData
