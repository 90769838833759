import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { deleteBanner, getBannerById } from 'app/redux/store/features/banners'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import useJumboTheme from '../../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const BannerGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { showDialog, hideDialog } = useJumboDialog()
    const dateTimeColumns = ['dateStart', 'dateEnd']
    const booleanColumns = ['isActive']
    const [sorting, setSorting] = useState([{ columnName: 'fileName', direction: 'asc' }])
    const pageSize = 25
    const { banners, loading, error, refreshing } = useSelector((state) => state.banners)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getBannerById({ id: id }))
        navigate(`/settings/configuration/banner-bar/edit/${id}`, { state: { id: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton
                    onClick={() => {
                        onNavigatingToDetail(row.id)
                    }}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveBanner(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.fileName}
            </Link>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')

    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const hideDialogAndRefreshBannerBarList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Banner Bar removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveBanner = async (id) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteBanner({ id: id }))
                hideDialogAndRefreshBannerBarList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'fileName', title: 'File Name', getCellValue: renderLinkCell },
        { name: 'fileSize', title: 'Size' },
        { name: 'filePath', title: 'Path' },
        { name: 'dateStart', title: 'Start Date' },
        { name: 'dateEnd', title: 'End Date' },
        { name: 'orderBy', title: 'Order' },
        { name: 'isActive', title: 'Enabled' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'fileName', width: min ? '170px' : '12%', minWidth: '170px', wordWrapEnabled: true },
            { columnName: 'fileSize', width: min ? '80px' : '8%', minWidth: '80px', wordWrapEnabled: true },
            { columnName: 'filePath', width: min ? '460px' : '36%', minWidth: '460px', wordWrapEnabled: true },
            { columnName: 'dateStart', width: min ? '110px' : '11%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'dateEnd', width: min ? '110px' : '11%', minWidth: '110px', wordWrapEnabled: true },
            {
                columnName: 'orderBy',
                width: min ? '90px' : '7%',
                align: 'center',
                minWidth: '90px',
                wordWrapEnabled: true,
            },
            {
                columnName: 'isActive',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                wordWrapEnabled: true,
                align: 'center',
            },
            {
                columnName: 'Action',
                width: min ? '90px' : '7%',
                minWidth: '90px',
                wordWrapEnabled: true,
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])
    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={banners.data} columns={columns}>
                {banners.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <DateTimeTypeProvider for={dateTimeColumns} />
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={banners.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default BannerGridData
