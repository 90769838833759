import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Divider from '@mui/material/Divider'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, GroupItem, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import CustomStore from 'devextreme/data/custom_store'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import DataSource from 'devextreme/data/data_source'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'

const UpdateTeacherGridForm = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const [labelLocation, setLabelLocation] = useState('left')
    const { loading } = useSelector((state) => state.teachers)
    const sm = useMediaQuery('(max-width:600px)')
    const [resetPasswordData, setResetPasswordData] = useState({
        password: '',
        verifyPassword: '',
    })

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const handlePasswordChange = useCallback((e, fieldName) => {
        setResetPasswordData((prevState) => ({
            ...prevState,
            [fieldName]: e.value,
        }))
    }, [])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/security/teachers', { state: { navigateFrom: 'cancel-update-teacher' } }),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [{ type: 'required', message: 'Email is required.' }],

        password: resetPasswordData.password ? [{ type: 'required', message: 'Password is required.' }] : [],
        verifyPassword: resetPasswordData.verifyPassword
            ? [
                  { type: 'required', message: 'Verify Password is required.' },
                  {
                      type: 'custom',
                      validationCallback: (e) => (resetPasswordData.password ? !!e.value : true),
                      message: 'Please enter password and verify password',
                  },
                  {
                      type: 'custom',
                      validationCallback: (e) =>
                          resetPasswordData.password ? e.value === resetPasswordData.password : true,
                      message: 'Password and Verify Password do not match.',
                  },
              ]
            : [],
    }

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await customerLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return customerLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const customerEditorOptions = {
        dataSource: customerLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const phonePattern = /^\+[1-9]\d{10,14}$/

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Teacher
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form
                    onSubmit={handleSubmit((data) => {
                        if (resetPasswordData.password && !resetPasswordData.verifyPassword) {
                            enqueueSnackbar('Verify Password must be entered if Password is provided.', {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            })
                            return
                        }
                        if (
                            resetPasswordData.password &&
                            resetPasswordData.password !== resetPasswordData.verifyPassword
                        ) {
                            enqueueSnackbar('Password and Verify Password do not match.', {
                                variant: 'error',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                            })
                            return
                        }
                        props.updateTeacher(data)
                    })}>
                    <Form
                        mode='form'
                        formData={props.teacherData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem caption='Update Teacher' cssClass='form-group-item '>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='firstName' validationRules={validationRules.firstName}>
                                    <Label text='First Name' />
                                </SimpleItem>
                                <SimpleItem dataField='lastName' validationRules={validationRules.lastName}>
                                    <Label text='Last Name' />
                                </SimpleItem>
                                <SimpleItem dataField='phoneNumber' editorOptions={phoneEditorOptions}>
                                    <Label text='Phone Number' />
                                    <RequiredRule message='Phone Number is required' />
                                    <PatternRule
                                        message='Phone number must start with + and needs to include country code'
                                        pattern={phonePattern}
                                    />
                                </SimpleItem>
                                <SimpleItem dataField='email' validationRules={validationRules.email}>
                                    <Label text='Email' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='password'
                                    editorType='dxTextBox'
                                    validationRules={validationRules.password}
                                    editorOptions={{
                                        mode: 'password',
                                        value: resetPasswordData.password,
                                        onValueChanged: (e) => handlePasswordChange(e, 'password'),
                                        validationMessageMode: 'always',
                                    }}
                                />
                                <SimpleItem
                                    dataField='verifyPassword'
                                    editorType='dxTextBox'
                                    validationRules={validationRules.verifyPassword}
                                    editorOptions={{
                                        mode: 'password',
                                        value: resetPasswordData.verifyPassword,
                                        onValueChanged: (e) => handlePasswordChange(e, 'verifyPassword'),
                                        validationMessageMode: 'always',
                                    }}
                                />
                                <SimpleItem dataField='comments' editorType='dxTextArea'>
                                    <Label text='Comments' />
                                </SimpleItem>
                                <EmptyItem />
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='customerId'
                                    editorType='dxSelectBox'
                                    editorOptions={customerEditorOptions}>
                                    <Label text='Customer Name' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateTeacherGridForm
