import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DataSource from 'devextreme/data/data_source'
import RadioGroup from 'devextreme-react/radio-group'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CustomStore from 'devextreme/data/custom_store'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import familyAndMemberService from 'app/services/pages/reports/registrations/familyAndMemberService/familyAndMemberService'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import classesService from 'app/services/pages/courses/setup/classesServices/classesService'
import sectionService from 'app/services/pages/courses/setup/sectionServices/sectionService'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { useMediaQuery } from '@mui/material'

const AddEnrolmentForm = (props) => {
    const [visibleField, setVisibleField] = useState(true)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.enrolments)
    const JumboAuths = useJumboAuth()
    const currentTermId = JumboAuths.authUser.currentTermId
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    const [selection, setSelection] = useState({
        termId: currentTermId,
        classId: null,
        sectionId: null,
        familyId: null,
    })

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onTermValueChanged = (e) => {
        setSelection({
            ...selection,
            termId: e.value,
        })
    }

    const termLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await termServices.getTermLookupList({ termYear: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [termServices]
    )

    const termLookupById = useCallback(
        async (key) => {
            try {
                return await termServices.getTermLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [termServices]
    )

    const termLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await termLookupList(searchTerm)
            },
            byKey: function (key) {
                if (key === '') return utilServices.emptyList()

                return termLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const termYearEditorOptions = {
        dataSource: termLookupsStore,
        displayExpr: function (data) {
            return data ? data.termYear + ' / ' + data.termNumber : ''
        },
        valueExpr: 'id',
        onValueChanged: onTermValueChanged,
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data.termYear + ' / ' + data.termNumber
        },
    }

    const onClassValueChanged = (e) => {
        setSelection({
            ...selection,
            classId: e.value,
            sectionId: null,
        })
    }

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await classesService.getClassLookupList({ termId: selection.termId, className: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [classesService, selection.termId]
    )

    const classLookupById = useCallback(
        async (key) => {
            try {
                return await classesService.getClassLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [classesService]
    )

    const classLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await classLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return classLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const classNameEditorOptions = {
        dataSource: classLookupsStore,
        displayExpr: 'className',
        valueExpr: 'id',
        onValueChanged: onClassValueChanged,
        placeholder: 'Require a term selection',
        searchEnabled: true,
        showClearButton: true,
    }

    const sectionLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await sectionService.getSectionLookupList({
                    classId: selection.classId,
                    sectionName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [sectionService, selection.classId]
    )

    const sectionLookupById = useCallback(
        async (key) => {
            try {
                return await sectionService.getSectionLookupById(selection.classId, key)
            } catch (error) {
                handleError(error)
            }
        },
        [sectionService]
    )

    const sectionLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await sectionLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return sectionLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const sectionNameEditorOptions = {
        dataSource: sectionLookupsStore,
        displayExpr: 'sectionName',
        valueExpr: 'id',
        value: selection.sectionId,
        onValueChanged: (e) =>
            setSelection({
                ...selection,
                sectionId: e.value,
            }),
        placeholder: 'Require a term and class',
        searchEnabled: true,
        showClearButton: true,
    }

    const onFamilyValueChanged = (e) => {
        setSelection({
            ...selection,
            familyId: e.value,
        })
    }

    const familyLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await familyAndMemberService.getFamilyLookupList({ familyName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService]
    )

    const familyLookupById = useCallback(
        async (key) => {
            try {
                return await familyAndMemberService.getFamilyLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService]
    )

    const familyLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await familyLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return familyLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const familyNameEditorOptions = {
        dataSource: familyLookupsStore,
        itemTemplate: function (data) {
            return data ? data.familyName.toFamilyContactName(data.contactName) : ''
        },
        displayExpr: function (data) {
            return data ? data.familyName.toFamilyContactName(data.contactName) : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        onValueChanged: onFamilyValueChanged,
    }

    const memberLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await familyAndMemberService.getMemberLookupList({
                    familyId: selection.familyId,
                    firstName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService, selection.familyId]
    )

    const memberLookupById = useCallback(
        async (key) => {
            try {
                return await familyAndMemberService.getMemberLookupById(selection.familyId, key)
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService]
    )

    const memberStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await memberLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return memberLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const memberEditorOptions = {
        dataSource: memberStore,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await customerLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return await utilServices.emptyList()

                return customerLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
            },
        }),
        reshapeOnPush: true,
    })

    const [customerEditorOptions, setCustomerEditorOptions] = useState({
        dataSource: customerLookupsStore,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    })

    const [newCustomerEditorOptions, setNewCustomerEditorOptions] = useState({})

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/registrations/enrolments'),
    }

    const validationRules = {
        termYear: [{ type: 'required', message: 'Term name is required.' }],
        className: [{ type: 'required', message: 'Class name is required.' }],
        customerName: [{ type: 'required', message: 'Customer name is required.' }],
        firstName: [{ type: 'required', message: 'First name is required.' }],
        lastName: [{ type: 'required', message: 'Last name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        phoneNumber: [{ type: 'required', message: 'Phone number is required.' }],
    }

    const priorityEntities = [
        { id: 0, text: 'Existing Customer' },
        { id: 1, text: 'New Customer' },
    ]

    const [selectedRadioButton, setSelectedRadioButton] = useState(priorityEntities[0].id)

    const onSelectionChanged = (e) => {
        setSelectedRadioButton(priorityEntities[e.value].id)
        setVisibleField(!visibleField)
        if (visibleField) {
            setCustomerEditorOptions({
                value: '',
            })
        } else {
            setNewCustomerEditorOptions({
                value: '',
            })
        }
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Add Enrolment
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addEnrolment)}>
                    <Form
                        mode='form'
                        formData={props.enrolmentData}
                        showColonAfterLabel={false}
                        validationGroup='Enrolments'
                        labelLocation={labelLocation}>
                        <GroupItem caption='New Enrolment' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <SimpleItem
                                    dataField='termId'
                                    editorType='dxSelectBox'
                                    editorOptions={termYearEditorOptions}
                                    validationRules={validationRules.termYear}>
                                    <Label text='Term' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='classId'
                                    editorType='dxSelectBox'
                                    editorOptions={classNameEditorOptions}
                                    validationRules={validationRules.className}>
                                    <Label text='Class Name' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='sectionId'
                                    editorType='dxSelectBox'
                                    editorOptions={sectionNameEditorOptions}>
                                    <Label text='Section Name' tooltip='hi' />
                                </SimpleItem>
                                <EmptyItem />
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <EmptyItem />
                                <SimpleItem
                                    dataField='familyId'
                                    editorType='dxSelectBox'
                                    editorOptions={familyNameEditorOptions}>
                                    <Label text='Family Name' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='memberId'
                                    editorType='dxSelectBox'
                                    editorOptions={memberEditorOptions}>
                                    <Label text='Member Name' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                    </Form>
                    <Form
                        mode='form'
                        formData={props.enrolmentData}
                        showColonAfterLabel={false}
                        validationGroup='Enrolments'
                        labelLocation={labelLocation}>
                        <GroupItem cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <GroupItem>
                                    <ColCountByScreen lg={6} md={6} sm={2} xs={2} />
                                    <GroupItem />
                                    <GroupItem colSpan={5}>
                                        <RadioGroup
                                            items={priorityEntities}
                                            valueExpr='id'
                                            displayExpr='text'
                                            layout='horizontal'
                                            value={selectedRadioButton}
                                            onValueChanged={onSelectionChanged}
                                        />
                                    </GroupItem>
                                </GroupItem>
                                <EmptyItem />
                                <GroupItem>
                                    <Divider />
                                </GroupItem>
                                <EmptyItem />
                                <SimpleItem
                                    visible={visibleField}
                                    dataField='customerId'
                                    editorType='dxSelectBox'
                                    editorOptions={customerEditorOptions}
                                    validationRules={validationRules.customerName}>
                                    <Label text='Customer Name' />
                                </SimpleItem>
                                <GroupItem visible={!visibleField}>
                                    <SimpleItem
                                        dataField='firstName'
                                        editorOptions={newCustomerEditorOptions}
                                        validationRules={validationRules.firstName}
                                    />
                                    <SimpleItem
                                        dataField='lastName'
                                        editorOptions={newCustomerEditorOptions}
                                        validationRules={validationRules.lastName}
                                    />
                                    <SimpleItem
                                        dataField='email'
                                        editorOptions={newCustomerEditorOptions}
                                        validationRules={validationRules.email}
                                    />
                                    <SimpleItem
                                        dataField='phoneNumber'
                                        editorOptions={newCustomerEditorOptions}
                                        validationRules={validationRules.phoneNumber}
                                    />
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                validationGroup='customerData'
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddEnrolmentForm
