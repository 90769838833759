import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, ColCountByScreen, Label, GroupItem } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { updateIndicator } from 'app/redux/store/features/configs'
import { updateClass } from 'app/redux/store/features/classes'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useMediaQuery } from '@mui/material'

const UpdateClassForm = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleSubmit } = useForm()
    const { loading, error } = useSelector((state) => state.classes)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) props.handleError(error)
        else {
            enqueueSnackbar('Operation completed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            dispatch(updateIndicator({ inProgress: false }))
            navigate('/courses/setup/classes', { state: { navigateFrom: 'update-class' } })
        }
    }, [loading])

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/courses/setup/classes', { state: { navigateFrom: 'cancel-update-class' } }),
    }

    const validationRules = {
        className: [{ type: 'required', message: 'Class Name is required.' }],
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        termId: [{ type: 'required', message: 'Term is required.' }],
        subTitle: [{ type: 'required', message: 'Sub Title is required.' }],
        ageGroupId: [{ type: 'required', message: 'Age Group is required.' }],
        termInfo: [{ type: 'required', message: 'Term Info is required.' }],
        shortDesc: [{ type: 'required', message: 'Age Info is required.' }],
        description: [{ type: 'required', message: 'Description is required.' }],
        description2: [{ type: 'required', message: 'Description 2 is required.' }],
    }

    const CurrencyEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    const onUpdateClass = useCallback(async () => {
        setApiCallInProgress(true)
        dispatch(updateIndicator({ inProgress: true }))
        dispatch(updateClass({ ...props.classData }))
    }, [props.classData])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onUpdateClass)}>
                <Form
                    mode='form'
                    formData={props.classData}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    labelLocation={labelLocation}>
                    <GroupItem>
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={1} xs={1} />
                            <GroupItem caption='Details' cssClass='form-group-item'>
                                <GroupItem cssClass='form-group-item-margin'>
                                    <SimpleItem
                                        dataField='termId'
                                        editorType='dxSelectBox'
                                        validationRules={validationRules.termId}
                                        editorOptions={props.termEditorOptions}>
                                        <Label text='Term' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='locationId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.locationEditorOptions}>
                                        <Label text='Location Name' />
                                    </SimpleItem>
                                    <SimpleItem dataField='className' validationRules={validationRules.className} />
                                    <SimpleItem
                                        dataField='classTypeId'
                                        editorType='dxSelectBox'
                                        editorOptions={props.classTypeEditorOptions}>
                                        <Label text='Class Type' />
                                    </SimpleItem>
                                    <SimpleItem
                                        editorType='dxSelectBox'
                                        dataField='classGenderType'
                                        editorOptions={props.genderEditorOptions}
                                    />
                                    <SimpleItem dataField='subTitle' validationRules={validationRules.subTitle} />
                                    <SimpleItem
                                        editorType='dxSelectBox'
                                        dataField='ageGroupId'
                                        validationRules={validationRules.ageGroupId}
                                        editorOptions={props.ageGroupEditorOptions}>
                                        <Label text='Age Group' />
                                    </SimpleItem>
                                    <SimpleItem dataField='shortDesc' validationRules={validationRules.shortDesc}>
                                        <Label text='Age Info' />
                                    </SimpleItem>
                                    <SimpleItem dataField='termInfo' validationRules={validationRules.termInfo}>
                                        <Label text='Term Info' />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField='classPrice'
                                        editorType='dxNumberBox'
                                        editorOptions={CurrencyEditorOptions}>
                                        <Label text='Class Price' />
                                    </SimpleItem>
                                    <SimpleItem dataField='comments' editorType='dxTextArea' />
                                    <SimpleItem dataField='canDeposit' editorType='dxCheckBox'>
                                        <Label text='Deposit Enable' />
                                    </SimpleItem>
                                    <SimpleItem
                                        editorType='dxNumberBox'
                                        dataField='depositAmount'
                                        editorOptions={CurrencyEditorOptions}>
                                        <Label text='Deposit Amount' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <GroupItem>
                                <GroupItem caption='Configuration' cssClass='form-group-item '>
                                    <GroupItem cssClass='form-group-item-margin'>
                                        <SimpleItem
                                            dataField='dateStart'
                                            editorType='dxDateBox'
                                            validationRules={validationRules.dateStart}
                                        />
                                        <SimpleItem
                                            dataField='dateEnd'
                                            editorType='dxDateBox'
                                            validationRules={validationRules.dateEnd}
                                        />
                                        <SimpleItem
                                            dataField='newEnrolDays'
                                            editorType='dxNumberBox'
                                            editorOptions={{ showSpinButtons: 'true' }}>
                                            <Label text='Delay New Enrol (Days after start date)' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='reEnrolDays'
                                            editorType='dxNumberBox'
                                            editorOptions={{ showSpinButtons: 'true' }}>
                                            <Label text='Open ReEnrol (Days before end date)' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='prvClassId'
                                            editorType='dxSelectBox'
                                            editorOptions={props.prvClassEditorOptions}>
                                            <Label text='ReEnrol From Class (Last term)' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='teacherId'
                                            editorType='dxSelectBox'
                                            editorOptions={props.teacherEditorOptions}>
                                            <Label text='Teacher Name' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='capacity'
                                            editorType='dxNumberBox'
                                            editorOptions={{ showSpinButtons: 'true' }}
                                        />
                                        <SimpleItem
                                            dataField='txnDueDays'
                                            editorType='dxNumberBox'
                                            editorOptions={{ showSpinButtons: 'true' }}>
                                            <Label text='Payment Due (Days)' />
                                        </SimpleItem>
                                        <SimpleItem dataField='registerCode'>
                                            <Label text='Register Code' />
                                        </SimpleItem>
                                        <SimpleItem
                                            dataField='description'
                                            editorType='dxTextArea'
                                            validationRules={validationRules.description}
                                        />
                                        <SimpleItem
                                            dataField='description2'
                                            editorType='dxTextArea'
                                            validationRules={validationRules.description2}
                                        />
                                    </GroupItem>
                                </GroupItem>
                            </GroupItem>
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem
                            buttonOptions={{
                                disabled: loading,
                                icon: loading ? 'refresh' : 'check',
                                ...submitButtonOptions,
                            }}
                            horizontalAlignment='right'
                        />
                        <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default UpdateClassForm
