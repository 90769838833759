import React from 'react'

import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { Card, useMediaQuery } from '@mui/material'
import useReportApp from '../hooks/useReportApp'
import ReportAppSidebar from '../components/ReportAppSidebar'
import ReportAppContent from '../components/ReportAppContent'
import { useLocation } from 'react-router-dom'

const ReportAppLayout = () => {
    const { theme } = useJumboTheme()
    const { activeReport } = useReportApp()
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const location = useLocation()

    // Check if the current path is '/reports'
    const isReportsPage = location.pathname === '/reports'

    // Determine if the sidebar should be hidden on mobile screens
    const shouldHideSidebar = md && !isReportsPage

    // Determine if the content should be displayed
    const shouldHideContent = md && isReportsPage && !activeReport

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    display: md ? (shouldHideSidebar ? 'none' : 'block') : 'block',
                    width: md ? 'auto' : 280,
                    marginRight: 0,
                    borderRight: md ? 'none' : 1,
                    borderRightColor: (theme) => theme.palette.divider,
                },
            },
            wrapper: {
                component: Card,
                sx: {
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                    },
                },
            },
            content: {
                sx: {
                    display: shouldHideContent ? 'none' : 'block',
                },
            },
        }),
        [theme, md, activeReport, shouldHideSidebar, shouldHideContent, isReportsPage]
    )

    return (
        <JumboContentLayout sx={{ marginRight: '440px' }} sidebar={<ReportAppSidebar />} layoutOptions={layoutOptions}>
            <ReportAppContent />
        </JumboContentLayout>
    )
}

export default ReportAppLayout
