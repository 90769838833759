import React from 'react'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider'
import { Card, CardContent, ListItem, ListItemText, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import List from '@mui/material/List'
import OutputIcon from '@mui/icons-material/Output'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PaidIcon from '@mui/icons-material/Paid'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CommentIcon from '@mui/icons-material/Comment'
import Span from '@jumbo/shared/Span'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar'

const RightInfoCard = ({ transaction }) => {
    return (
        <Grid>
            <Card
                sx={{
                    height: { xs: 'auto', md: 300 },
                }}>
                <CardContent sx={{ justifyContent: 'center' }}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid
                            item
                            xs={12}
                            sx={{ width: '100%', display: 'flex', alignItems: 'center', ml: { xs: 1.9 } }}>
                            <List sx={{ width: '100%', color: 'text.primary' }}>
                                <ListItemText
                                    disablePadding
                                    primary={
                                        <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                            <RequestQuoteIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                            The amount
                                            <Span
                                                sx={{
                                                    color: 'secondary.dark',
                                                }}>
                                                {' '}
                                                {formatCurrency(transaction.amountPaid)}
                                            </Span>{' '}
                                            is paid and <br />
                                            <Span sx={{ marginLeft: '11%' }}>total amount is </Span>
                                            <Span sx={{ color: 'secondary.dark' }}>
                                                {' '}
                                                {formatCurrency(transaction.totalAmount)}.{' '}
                                            </Span>
                                        </Typography>
                                    }
                                />
                                <ListItemText sx={{ mt: 1.7 }}>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <SignalWifiStatusbar4BarIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Balance
                                        <Span sx={{ color: 'secondary.dark' }}>
                                            {' '}
                                            {formatCurrency(transaction.balance)}{' '}
                                        </Span>{' '}
                                        is due on <br />
                                        <Span sx={{ color: 'secondary.dark', marginLeft: '11%' }}>
                                            {transaction.dueDateTxn ? formattedDate(transaction.dueDateTxn) : ''}{' '}
                                        </Span>{' '}
                                        (due date)
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                        <Grid item sx={{ width: '45%', display: 'flex', alignItems: 'right', ml: 1.9 }}>
                            {' '}
                            <List sx={{ width: '100%' }}>
                                <ListItemText>
                                    <Typography variant={'h5'} color='text.primary' fontFamily='Lobster, cursive'>
                                        <CommentIcon sx={{ fontSize: 25, mb: -0.5, mr: 1.5 }} />
                                        Comments
                                    </Typography>
                                </ListItemText>
                                <Divider sx={{ mb: 2, width: '100%' }} />
                                <ListItemText>
                                    <Typography variant={'h5'} color='secondary.dark'>
                                        {transaction.comments && (
                                            <pre>
                                                <Span
                                                    style={{
                                                        fontSize: '1rem',
                                                        color: 'secondary.dark',
                                                        fontFamily: 'Lobster, cursive',
                                                        fontWeight: '400',
                                                        lineHeight: 1.5,
                                                        whiteSpace: 'pre-wrap',
                                                    }}>
                                                    {transaction.comments + ' '}
                                                </Span>
                                            </pre>
                                        )}
                                    </Typography>
                                </ListItemText>
                            </List>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default RightInfoCard
