import React from 'react'
import ReportAppContext from './ReportAppContext'

const ReportAppProvider = ({ children }) => {
    const [activeReport, setActiveReport] = React.useState(null)
    const [activeReportColorList, setActiveReportColorList] = React.useState([])
    const activeReportRef = React.useRef()
    const enrolmentReportListRef = React.useRef()
    const paymentReportListRef = React.useRef()

    const contextValue = React.useMemo(
        () => ({
            activeReport: activeReport,
            setActiveReport: setActiveReport,
            activeReportColorList: activeReportColorList,
            setColorActiveReport: setActiveReportColorList,
            activeReportRef: activeReportRef,
            enrolmentReportListRef: enrolmentReportListRef,
            paymentReportListRef: paymentReportListRef,
        }),
        [activeReport, activeReportColorList, activeReportRef, enrolmentReportListRef, paymentReportListRef]
    )
    React.useEffect(() => {
        const found = activeReportColorList.find(function (element) {
            return element.id === activeReport?.id
        })
        // Reset the list as we will be setting a new id
        const resetList = activeReportColorList.map((c, i) => {
            return { id: c.id, color: 'text.primary' }
        })

        // Add or update
        if (found) {
            const newList = resetList.map((c, i) => {
                if (c.id === activeReport?.id) {
                    return { id: c.id, color: 'text.primary' }
                }
                return { id: c.id, color: 'text.primary' }
            })
            setActiveReportColorList(newList)
        } else {
            let newItem = { id: activeReport?.id, color: 'text.primary' }
            let updatedItems = [...resetList, newItem]
            setActiveReportColorList(updatedItems)
        }
    }, [setActiveReportColorList, activeReport])

    return <ReportAppContext.Provider value={contextValue}>{children}</ReportAppContext.Provider>
}

export default ReportAppProvider
