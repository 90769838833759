import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import Typography from '@mui/material/Typography'
import { Paper, useMediaQuery } from '@mui/material'
import {
    clearPlacement,
    getPlacementList,
    updatePlacement,
    updatePlacementConfig,
} from 'app/redux/store/features/placements'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const AllocatedGrid = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const dateTimeColumns = ['createTime']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    //const [selectedRowData, setSelectedRowData] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25
    const {
        placements,
        placementConfig,
        loading: placementLoading,
        error: placementError,
        refreshing,
    } = useSelector((state) => state.placements)
    const { termConfig, loading: termLoading, error: termError } = useSelector((state) => state.terms)
    const { classConfig, loading: classLoading, error: classError } = useSelector((state) => state.classes)
    const { sectionConfig, loading: sectionLoading, error: sectionError } = useSelector((state) => state.sections)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (sectionConfig.sectionId) {
            onGetPlacement()
        } else dispatch(clearPlacement())
    }, [sectionConfig.sectionId])

    const onGetPlacement = (filter, refresh = false) => {
        if (termConfig.termId) {
            setApiCallInProgress(true)
            dispatch(
                getPlacementList(
                    {
                        ...filter,
                        termId: termConfig.termId,
                        classId: classConfig.classId,
                        sectionId: sectionConfig.sectionId,
                        PhoneNumber: '',
                        skip: 0,
                        take: pageSize,
                    },
                    refresh // Pass the refresh parameter here
                )
            )
            setCurrentPage(0)
        }
    }

    useEffect(() => {
        if (placementConfig.reloadAfterUpdate) {
            if (placementError) {
                enqueueSnackbar(placementError.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar('Section data removed successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
                onGetPlacement() // To refresh the Placement list
            }
            dispatch(updatePlacementConfig({ reloadAfterUpdate: false }))
        }
    }, [placementConfig.reloadAfterUpdate])

    useEffect(() => {
        if (!apiCallInProgress || placementLoading || termLoading || classLoading || sectionLoading) return
        setApiCallInProgress(false)
        const error = placementError || termError || classError || sectionError
        if (error) handleError(error)
    }, [placementLoading, termLoading, classLoading, sectionLoading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { skip: pageSize * pageNumber, take: pageSize }
        onGetPlacement(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        onGetPlacement(currentFilter)
    }
    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }
    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={placementLoading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (rowData) => {
        return (
            <div>
                <IconButton onClick={() => onRemoveUser(rowData)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderStudentNameCell = (row) => {
        return row.firstName ? row.firstName + ' ' + row.lastName : ''
    }

    const onRemoveUser = async (rowData) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected enrolment, do you want to continue?',
            onYes: () => {
                //setSelectedRowData(rowData)
                dispatch(
                    updatePlacement({
                        data: [
                            {
                                id: rowData.id,
                                termId: rowData.termId,
                                classId: rowData.classId,
                                sectionId: '',
                            },
                        ],
                    })
                )
                hideDialog()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'firstName', title: 'Student Name', getCellValue: renderStudentNameCell },
        { name: 'familyName', title: 'Family Name' },
        { name: 'sectionName', title: 'Section Name' },
        { name: 'createTime', title: 'Create Time' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'firstName', width: min ? '130px' : '23%', wordWrapEnabled: true, minWidth: '130px' },
            { columnName: 'familyName', width: min ? '130px' : '21%', wordWrapEnabled: true, minWidth: '130px' },
            { columnName: 'sectionName', width: min ? '130px' : '20%', wordWrapEnabled: true, minWidth: '120px' },
            { columnName: 'createTime', width: min ? '110px' : '22%', wordWrapEnabled: true, minWidth: '110px' },
            {
                columnName: 'Action',
                width: min ? '90px' : '14%',
                wordWrapEnabled: true,
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        fontSize={'large'}
                        onClick={() => onGetPlacement({}, true)} // Pass true as the second argument
                        sx={{
                            mb: -1.3,
                            mr: 1,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Allocated Enrolments
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'success.main',
                pl: 1,
            }}>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={placements.data} columns={columns}>
                    {placements.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <DateTimeTypeProvider for={dateTimeColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={placements.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default AllocatedGrid
