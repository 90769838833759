import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import util from 'app/utils/components/getYears/getNextFiveYear'
import dxTextArea from 'devextreme/ui/text_area'
import { useSelector } from 'react-redux'
import termServices from '../../../../../../services/pages/courses/setup/termServices/termServices'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from '../../../../../../services/util-services'
import { useSnackbar } from 'notistack'
import { useMediaQuery } from '@mui/material'

const UpdateTermForm = (props) => {
    const navigate = useNavigate()
    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.terms)
    const { enqueueSnackbar } = useSnackbar()
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const termYearEditorOptions = {
        dataSource: util.getNextFiveYear(),
    }

    const createdByEditorOptions = {
        readOnly: true,
    }

    const termLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await termServices.getTermLookupList({ termYear: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [termServices]
    )

    const termLookupById = useCallback(
        async (key) => {
            try {
                return await termServices.getTermLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [termServices]
    )

    const termLookupsStore = new DataSource({
        store: new CustomStore({
            key: 'id',
            load: async function (loadOptions) {
                let searchTerm = ''
                if (loadOptions.searchValue) {
                    searchTerm = loadOptions.searchValue
                }
                return await termLookupList(searchTerm)
            },
            byKey: async function (key) {
                if (key === '') return utilServices.emptyList()

                return termLookupById(key)
            },
            errorHandler: function (e) {
                console.log(e)
                return utilServices.emptyList()
            },
        }),
        reshapeOnPush: true,
    })

    const termEditorOptions = {
        dataSource: termLookupsStore,
        displayExpr: function (data) {
            return data ? data.termYear + ' / ' + data.termNumber : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: function (data) {
            return data.termYear + ' / ' + data.termNumber
        },
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/courses/setup/terms'),
    }

    const validationRules = {
        termYear: [{ type: 'required', message: 'Term year is required.' }],
        termName: [{ type: 'required', message: 'Term Name is required.' }],
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        prvTermId: [{ type: 'required', message: 'Previous Term is required.' }],
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Term
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.updateTerm)}>
                    <Form
                        mode='form'
                        formData={props.termData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem caption='Update Term' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='isCurrent' editorType='dxCheckBox'>
                                    <Label text='This is a current term' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='termYear'
                                    editorType='dxSelectBox'
                                    editorOptions={termYearEditorOptions}
                                    validationRules={validationRules.termYear}
                                />
                                <SimpleItem
                                    dataField='termNumber'
                                    editorType='dxNumberBox'
                                    editorOptions={{ showSpinButtons: 'true' }}>
                                    <Label text='Term Number' />
                                </SimpleItem>
                                <SimpleItem dataField='termName' validationRules={validationRules.termName} />
                                <SimpleItem dataField='comments' editorType={dxTextArea} />
                                <SimpleItem
                                    dataField='dateStart'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateStart}
                                />
                                <SimpleItem
                                    dataField='dateEnd'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateEnd}
                                />
                                <SimpleItem
                                    dataField='prvTermId'
                                    editorType='dxSelectBox'
                                    validationRules={validationRules.prvTermId}
                                    editorOptions={termEditorOptions}>
                                    <Label text='Pervious Term' />
                                </SimpleItem>
                                <SimpleItem dataField='createdBy' editorOptions={createdByEditorOptions} />
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateTermForm
