import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { SelectBox } from 'devextreme-react/select-box'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { useSnackbar } from 'notistack'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getSectionList, getSections, updateSectionConfig } from 'app/redux/store/features/sections'
import { getTermLookupList, updateTermConfig } from 'app/redux/store/features/terms'
import { getClassLookupList, updateClassConfig } from 'app/redux/store/features/classes'
import SectionGridData from './components/SectionGridData'
import SectionGridFilter from './components/SectionGridFilter'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import utilServices from '../../../../../services/util-services'
import { useJumboTheme } from '@jumbo/hooks'

const SectionsGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [filter, setFilter] = useState()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [gridFilterVisible, setGridFilterVisible] = useState()
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25
    const { authUser } = useJumboAuth()
    const { termLookups, termConfig, loading: termLoading, error: termError } = useSelector((state) => state.terms)
    const {
        classLookups,
        classConfig,
        loading: classLoading,
        error: classError,
    } = useSelector((state) => state.classes)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    useEffect(() => {
        dispatch(getTermLookupList({ search: '' }))
    }, [])

    useEffect(() => {
        // This will only handle first time - every other time, it will be on change event
        if (termLookups.length > 0 && authUser?.currentTermId != null && termConfig.termId == null) {
            dispatch(updateTermConfig({ termId: authUser.currentTermId }))
            dispatch(getClassLookupList({ termId: authUser.currentTermId }))
        }
    }, [termLookups, authUser])

    // useEffect(() => {
    //     if (classLookups.length > 0)
    //         dispatch(updateClassConfig({ classId: classLookups[0].id }))
    // }, [classLookups])

    useEffect(() => {
        const error = termError || classError
        if (error) handleError()
    }, [termLoading, classLoading])

    const termLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'id',
                    data: termLookups,
                    totalCount: termLookups.length,
                }),
                reshapeOnPush: true,
            }),
        [termLookups]
    )

    const classLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new ArrayStore({
                    key: 'id',
                    data: classLookups,
                    totalCount: classLookups.length,
                }),
                reshapeOnPush: true,
            }),
        [classLookups]
    )

    // useEffect(() => {
    //     setApiCallInProgress(true)
    //     if (classConfig.classId) {
    //         setFilter({
    //             classId: classConfig.classId,
    //         })
    //         dispatch(getSectionList({ classId: classConfig.classId, skip: 0, take: pageSize }))
    //     }
    // }, [classConfig.classId])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getSectionList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getSectionList(currentFilter))
        setFilter(currentFilter)
    }
    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            currentfilter = { ...currentfilter, classId: classConfig.classId }
            dispatch(getSectionList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getSectionList({ ...filter, classId: classConfig?.classId ?? '', skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const onTermChanged = (value) => {
        // Update term id in redux and reload class drop down
        dispatch(updateTermConfig({ termId: value }))
        dispatch(getClassLookupList({ termId: value }))

        // Also clear the class config selection
        dispatch(updateClassConfig({ classId: null }))
        dispatch(getSections({ data: [], totalCount: 0 }))
    }
    const onClassChanged = (value) => {
        if (!utilServices.isNullOrUndefined(value)) {
            dispatch(getSectionList({ classId: value, skip: 0, take: pageSize }))
            dispatch(updateClassConfig({ classId: value }))
        } else {
            dispatch(updateClassConfig({ classId: value }))
            dispatch(getSections({ data: [], totalCount: 0 }))
            // Also clear section id
            dispatch(updateSectionConfig({ sectionId: null }))
        }
    }

    return (
        <Grid>
            <Grid>
                <Div
                    className='grid-filter-parent'
                    style={{ display: 'flex', margin: '-2% 1.5% 3.5% 1.8%', flexWrap: 'wrap' }}>
                    <Div sx={{ mr: 3, mt: 1.5 }}>
                        <Div>
                            <Typography>Select Term</Typography>
                        </Div>
                        <Div sx={{ mt: 0.5 }}>
                            <SelectBox
                                dataSource={termLookupsStore}
                                displayExpr={(data) => (data ? data.termYear + ' / ' + data.termNumber : '')}
                                itemTemplate={(data) => data.termYear + ' / ' + data.termNumber}
                                valueExpr={'id'}
                                value={termConfig.termId}
                                onValueChange={onTermChanged}
                                searchEnabled={true}
                                placeholder='All'
                            />
                        </Div>
                    </Div>
                    <Div sx={{ mr: 3, mt: 1.5 }}>
                        <Div>
                            <Typography>Select Class</Typography>
                        </Div>
                        <Div sx={{ mt: 0.5 }}>
                            <SelectBox
                                dataSource={classLookupsStore}
                                displayExpr={'className'}
                                valueExpr={'id'}
                                value={classConfig.classId}
                                onValueChange={onClassChanged}
                                searchEnabled={true}
                                showClearButton={true}
                            />
                        </Div>
                    </Div>
                </Div>
            </Grid>
            <Grid sx={{ paddingLeft: '1.8%', marginTop: min ? '20px' : '' }}>
                <JumboCardQuick
                    noWrapper
                    title={
                        <Typography color={'common.white'} variant={'h3'}>
                            <AutorenewRoundedIcon
                                onClick={onRefresh}
                                fontSize={'large'}
                                sx={{
                                    mb: -1.3,
                                    mr: 1,
                                    ml: -2,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                            Section
                        </Typography>
                    }
                    headerSx={{
                        height: 40,
                        bgcolor: 'success.main',
                    }}
                    action={
                        <Stack direction={'row'} spacing={4} sx={{ mt: 0, mr: -1 }}>
                            <FilterAltIcon
                                fontSize='large'
                                sx={{
                                    mt: -1.8,
                                    color: 'common.white',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setGridFilterVisible(!gridFilterVisible)}
                            />
                        </Stack>
                    }>
                    <Grid>
                        {gridFilterVisible && <SectionGridFilter mutation={applyFilter} handleError={handleError} />}
                    </Grid>
                    <Grid>
                        <SectionGridData
                            onRefresh={onRefresh}
                            onPageChange={onPageChange}
                            onColumnSort={onColumnSort}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            apiCallInProgress={apiCallInProgress}
                            setApiCallInProgress={setApiCallInProgress}
                        />
                    </Grid>
                </JumboCardQuick>
            </Grid>
        </Grid>
    )
}
export default SectionsGrid
