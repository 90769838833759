import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import GenderTypeService from './GenderTypeService'
import { PatternRule, RequiredRule } from 'devextreme-react/data-grid'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useMediaQuery } from '@mui/material'

const AddCustomerGridForm = (props) => {
    const navigate = useNavigate()
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')
    const [resetPasswordData, setResetPasswordData] = useState({
        password: '',
        verifyPassword: '',
    })

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const handlePasswordChange = useCallback(
        (e, fieldName) => {
            setResetPasswordData((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }))

            if (fieldName === 'password') {
                setPasswordMatch(e.value === resetPasswordData.verifyPassword)
            } else if (fieldName === 'verifyPassword') {
                setPasswordMatch(e.value === resetPasswordData.password)
            }
        },
        [resetPasswordData]
    )

    const { handleSubmit } = useForm()
    const { loading } = useSelector((state) => state.customers)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/registrations/customers'),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Email is invalid.' },
        ],
        password: [{ type: 'required', message: 'Password is required.' }],

        verifyPassword: [
            { type: 'required', message: 'Verify Password is required.' },
            {
                type: 'custom',
                validationCallback: () => passwordMatch,
                message: 'Password and Verify Password do not match.',
            },
        ],

        genderType: [{ type: 'required', message: 'Gender is required.' }],
    }

    const genderEditorOptions = {
        dataSource: GenderTypeService.getGenderType(),
        displayExpr: 'genderType',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const phoneEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const phonePattern = /^\+[1-9]\d{10,14}$/

    const maxOutstandingAmountEditorOptions = {
        format: (data) => formatCurrency(data), // Pass the formatCurrency function directly
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Add New Customer
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form onSubmit={handleSubmit(props.addCustomer)}>
                    <Form
                        mode='form'
                        formData={props.customerData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}
                        labelLocation={labelLocation}>
                        <GroupItem caption='New Customer' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='firstName' validationRules={validationRules.firstName}>
                                    <Label text='First Name' />
                                </SimpleItem>
                                <SimpleItem dataField='lastName'>
                                    <Label text='Last Name' />
                                </SimpleItem>
                                <SimpleItem dataField='phoneNumber' editorOptions={phoneEditorOptions}>
                                    <Label text='Phone Number' />
                                    <RequiredRule message='Phone Number is required' />
                                    <PatternRule
                                        message='phone number must start with + and needs to include country code'
                                        pattern={phonePattern}
                                    />
                                </SimpleItem>
                                <SimpleItem dataField='email' validationRules={validationRules.email}>
                                    <Label text='Email' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='password'
                                    editorType='dxTextBox'
                                    validationRules={validationRules.password}
                                    editorOptions={{
                                        mode: 'password',
                                        value: resetPasswordData.password,
                                        onValueChanged: (e) => handlePasswordChange(e, 'password'),
                                        validationMessageMode: 'always',
                                    }}
                                />
                                <SimpleItem
                                    dataField='verifyPassword'
                                    editorType='dxTextBox'
                                    validationRules={validationRules.verifyPassword}
                                    editorOptions={{
                                        mode: 'password',
                                        value: resetPasswordData.verifyPassword,
                                        onValueChanged: (e) => handlePasswordChange(e, 'verifyPassword'),
                                        validationMessageMode: 'always',
                                    }}
                                />
                                <SimpleItem
                                    editorType='dxSelectBox'
                                    dataField='genderType'
                                    validationRules={validationRules.genderType}
                                    editorOptions={genderEditorOptions}>
                                    <Label text='Gender Type' />
                                </SimpleItem>
                                <SimpleItem dataField='birthDate' editorType='dxDateBox'>
                                    <Label text='Birth Date' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='maxOutstandingAmount'
                                    editorType='dxNumberBox'
                                    editorOptions={maxOutstandingAmountEditorOptions}>
                                    <Label text='Max Due Amount Limit' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddCustomerGridForm
