import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { deleteCustomer } from 'app/redux/store/features/customers'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { Paper, useMediaQuery } from '@mui/material'
import { getCustomerById } from 'app/redux/store/features/customers'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import GenderTypeService from './GenderTypeService'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const CustomerGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const dateTimeColumns = ['createTime', 'updateTime']
    const dateColumns = ['birthDate']
    const booleanColumns = ['isActive']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const pageSize = 25
    const { customers, loading, error, refreshing } = useSelector((state) => state.customers)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }
    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getCustomerById({ id: id }))
        navigate(`/registrations/customers/edit/${id}`, { state: { customerId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formattedDate} {...props} />

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {row.firstName}
            </Link>
        )
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton>
                    <EditIcon
                        sx={{ color: 'primary.main', fontSize: '18px' }}
                        onClick={() => {
                            onNavigatingToDetail(row.id)
                        }}
                    />
                </IconButton>
                <IconButton>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} onClick={() => onRemoveUser(row.id)} />
                </IconButton>
            </div>
        )
    }

    const BooleanFormatter = ({ value }) => <span>{formatBooleanValue(value)}</span>
    const formatBooleanValue = (value) => (value ? 'Yes' : 'No')

    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Customer data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (userId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteCustomer({ id: userId }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'firstName', title: 'First Name', getCellValue: renderLinkCell },
        { name: 'lastName', title: 'Last Name', width: 400 },
        { name: 'phoneNumber', title: 'Phone Number' },
        { name: 'email', title: 'Email' },
        { name: 'genderType', title: 'Gender', getCellValue: GenderTypeService.getGenderTypeData },
        { name: 'birthDate', title: 'Birth Date' },
        { name: 'createTime', title: 'Created Date' },
        { name: 'updateTime', title: 'Updated Date' },
        { name: 'isActive', title: 'Enabled' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'firstName', width: min ? '110px' : '10%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'lastName', width: min ? '110px' : '10%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'phoneNumber', width: min ? '130px' : '10%', wordWrapEnabled: true, minWidth: '130px' },
            { columnName: 'email', width: min ? '220px' : '17%', minWidth: '220px' },
            { columnName: 'genderType', width: min ? '100px' : '8%', minWidth: '100px' },
            { columnName: 'birthDate', width: min ? '110px' : '8%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'createTime', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'updateTime', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            {
                columnName: 'isActive',
                width: min ? '100px' : '8%',
                minWidth: '100px',
                align: 'center',
            },
            {
                columnName: 'Action',
                width: min ? '90px' : '7%',
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={customers.data} columns={columns}>
                {customers.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <DateTimeTypeProvider for={dateTimeColumns} />
                <DateTypeProvider for={dateColumns} />
                <BooleanTypeProvider for={booleanColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={customers.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default CustomerGridData
