import React, { useState, useCallback, useMemo } from 'react'
import DataSource from 'devextreme/data/data_source'
import { SelectBox } from 'devextreme-react/select-box'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import CustomStore from 'devextreme/data/custom_store'
import utilServices from 'app/services/util-services'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import classesService from 'app/services/pages/courses/setup/classesServices/classesService'
import sectionService from 'app/services/pages/courses/setup/sectionServices/sectionService'
import familyAndMemberService from 'app/services/pages/reports/registrations/familyAndMemberService/familyAndMemberService'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'

const EnrolmentListReportFilter = ({ mutation }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    let initialValues = {
        sort: '',
    }

    const [filter, setFilter] = useState({ customerId: null })
    const initialFilterFields = {
        termSelectedData: '',
        classSelectedData: '',
        sectionSelectedData: null,
        familySelectedData: null,
        memberSelectedData: null,
    }

    const [filterFields, setFilterFields] = useState({ ...initialFilterFields })

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    const onTermValueChanged = async (e) => {
        if (e) {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                termSelectedData: e.id,
            }))
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                termSelectedData: '',
                classSelectedData: '',
            }))
        }
    }

    const onClassValueChanged = (e) => {
        if (e) {
            setFilterFields({
                ...filterFields,
                classSelectedData: e.id,
            })
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                classSelectedData: '',
                sectionSelectedData: null,
            }))
        }
    }

    const onSectionValueChanged = (e) => {
        if (e) {
            setFilterFields({
                ...filterFields,
                sectionSelectedData: e.id,
            })
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                sectionSelectedData: null,
            }))
        }
    }

    const onFamilyValueChanged = async (e) => {
        if (e) {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                familySelectedData: e.id,
            }))
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                familySelectedData: null,
                memberSelectedData: null,
            }))
        }
    }

    const onMemberValueChanged = (e) => {
        if (e) {
            setFilterFields({
                ...filterFields,
                memberSelectedData: e.id,
            })
        } else {
            setFilterFields((prevFilterFields) => ({
                ...prevFilterFields,
                memberSelectedData: null,
            }))
        }
    }

    const termLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await termServices.getTermLookupList({ termYear: searchTerm })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [termServices]
    )

    const termLookupById = useCallback(
        async (key) => {
            try {
                return await termServices.getTermLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },

        [termServices]
    )

    const termStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (!filterFields.termSelectedData) return []
                return await classesService.getClassLookupList({
                    termId: filterFields.termSelectedData,
                    className: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [classesService, filterFields.termSelectedData]
    )

    const classLookupById = useCallback(
        async (key) => {
            try {
                return await classesService.getClassLookupById(filterFields.termSelectedData, key)
            } catch (error) {
                handleError(error)
            }
        },

        [classesService]
    )

    const classStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()

                        return classLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [filterFields.termSelectedData]
    )

    const sectionLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (!filterFields.classSelectedData) return []
                return await sectionService.getSectionLookupList({
                    classId: filterFields.classSelectedData,
                    sectionName: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [sectionService, filterFields.classSelectedData]
    )

    const sectionLookupById = useCallback(
        async (key) => {
            try {
                return await sectionService.getSectionLookupById(filterFields.classSelectedData, key)
            } catch (error) {
                handleError(error)
            }
        },

        [sectionService]
    )

    const sectionStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await sectionLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return sectionLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [filterFields.classSelectedData]
    )

    const familyLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await familyAndMemberService.getFamilyLookupList({
                    familyName: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [familyAndMemberService]
    )

    const familyLookupById = useCallback(
        async (key) => {
            try {
                return await familyAndMemberService.getFamilyLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },

        [familyAndMemberService]
    )

    const familyStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await familyLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return familyLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const memberLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (utilServices.isNullOrUndefined(filterFields.familySelectedData)) return { totalCount: 0, data: [] }
                return await familyAndMemberService.getMemberLookupList({
                    familyId: filterFields.familySelectedData,
                    firstName: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [familyAndMemberService, filterFields.familySelectedData]
    )

    const memberLookupById = useCallback(
        async (key) => {
            try {
                return await familyAndMemberService.getMemberLookupById(filterFields.familySelectedData, key)
            } catch (error) {
                handleError(error)
            }
        },

        [familyAndMemberService]
    )

    const memberStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await memberLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return memberLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        [filterFields.familySelectedData]
    )

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({
                    firstName: searchTerm,
                })
            } catch (error) {
                handleError(error)
                return []
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },

        [customerServices]
    )

    const customerStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return customerLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = (sort) => {
        mutation.mutate({
            ...filter,
            sort: sort,
            termId: utilServices.isNullOrUndefined(filterFields.termSelectedData) ? '' : filterFields.termSelectedData,
            classId: utilServices.isNullOrUndefined(filterFields.classSelectedData)
                ? ''
                : filterFields.classSelectedData,
            sectionId: utilServices.isNullOrUndefined(filterFields.sectionSelectedData)
                ? null
                : filterFields.sectionSelectedData,
            familyId: utilServices.isNullOrUndefined(filterFields.familySelectedData)
                ? null
                : filterFields.familySelectedData,
            memberId: utilServices.isNullOrUndefined(filterFields.memberSelectedData)
                ? null
                : filterFields.memberSelectedData,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.sort)
        setSubmitting(false)
    }

    const handleClear = () => {
        setFilterFields({ ...initialFilterFields })
        setFilter({ customerId: null })
    }

    const onCustomerSelectionChanged = (value) => {
        setFilter({ ...filter, customerId: value?.id })
    }
    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper id='filterBody' variant={'primary'} sx={{ display: '', borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='report-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Term</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={termStore}
                                        itemTemplate={(data) => data.termYear + ' / ' + data.termNumber}
                                        displayExpr={(data) => (data ? data.termYear + ' / ' + data.termNumber : '')}
                                        value={filterFields.termSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onTermValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Class</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={classStore}
                                        displayExpr={'className'}
                                        value={filterFields.classSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onClassValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Section</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={sectionStore}
                                        displayExpr={'sectionName'}
                                        value={filterFields.sectionSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onSectionValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Customer </Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={customerStore}
                                        itemTemplate={(data) => data.firstName + '  ' + data.lastName}
                                        displayExpr={(data) => (data ? data.firstName + '  ' + data.lastName : '')}
                                        value={filter.customerId}
                                        searchEnabled={true}
                                        onValueChange={onCustomerSelectionChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Family </Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={familyStore}
                                        value={filterFields.familySelectedData}
                                        displayExpr={'familyName'}
                                        searchEnabled={true}
                                        onValueChange={onFamilyValueChanged}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Participant </Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 220 }}
                                        dataSource={memberStore}
                                        itemTemplate={(data) => data.firstName + '  ' + data.lastName}
                                        displayExpr={(data) => (data ? data.firstName + '  ' + data.lastName : '')}
                                        value={filterFields.memberSelectedData}
                                        searchEnabled={true}
                                        onValueChange={onMemberValueChanged}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div className='report-filter-Button' sx={{ mt: 4.5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        variant={'contained'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'contained'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default EnrolmentListReportFilter
