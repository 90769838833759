import React, { useEffect, useState, useMemo } from 'react'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { useSelector } from 'react-redux'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import { useMediaQuery } from '@mui/material'
import useJumboTheme from '../../../../../../../../@jumbo/hooks/useJumboTheme'

const TxnItemsDataGrid = ({ onColumnSort, apiCallInProgress, setApiCallInProgress }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { transactionItems, loading, error } = useSelector((state) => state.transactions)
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'desc' }])
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const fullNamecellRender = (data) => {
        return data.firstName + ' ' + data.lastName
    }

    const termCellRender = (data) => {
        return data.termYear + ' / ' + data.termNumber
    }

    const columns = [
        { name: 'txnItemName', title: 'Course Name' },
        { name: 'termYear', title: 'Term', getCellValue: termCellRender },
        { name: 'firstName', title: 'Participant Name', getCellValue: fullNamecellRender },
        { name: 'locationName', title: 'Location' },
        { name: 'subTotal', title: 'Sub Total', getCellValue: (row) => formatCurrency(row.subTotal) },
        { name: 'totalAmount', title: 'Total', getCellValue: (row) => formatCurrency(row.totalAmount) },
    ]

    const tableColumnExtensions = useMemo(
        () => [
            { columnName: 'txnItemName', width: min ? '150px' : '14%', wordWrapEnabled: true },
            { columnName: 'termYear', align: 'center', width: min ? '140px' : '14%', wordWrapEnabled: true },
            { columnName: 'firstName', width: min ? '200px' : '14%', wordWrapEnabled: true },
            { columnName: 'locationName', width: min ? '140px' : '14%', wordWrapEnabled: true },
            { columnName: 'subTotal', align: 'right', width: min ? '140px' : '22%', wordWrapEnabled: true },
            { columnName: 'totalAmount', align: 'right', width: min ? '140px' : '22%', wordWrapEnabled: true },
        ],
        [min]
    )

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={transactionItems.data} columns={columns}>
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />

                    <Table columnExtensions={tableColumnExtensions} />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                </Grid>
            }
        </Paper>
    )
}

export default TxnItemsDataGrid
