import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import Divider from '@mui/material/Divider'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'

const FamilyMemberGridFilter = ({ mutation, display, filterIconColor }) => {
    const [deletedRecordsSelection, setDeletedRecordsSelection] = useState(false)

    let initialValues = {
        firstName: '',
        lastName: '',
        includeDeletedRecords: false,
    }
    const onFormSubmit = (firstName, lastName) => {
        mutation.mutate({
            firstName: firstName,
            lastName: lastName,
            includeDeletedRecords: deletedRecordsSelection,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.firstName, data.lastName)
        setSubmitting(false)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            onReset={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>First Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='firstName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Last Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='lastName' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 3.5 }}>
                                <Div sx={{ mt: 0.5 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            type='checkbox'
                                            checked={deletedRecordsSelection}
                                            onChange={(e) => setDeletedRecordsSelection(!deletedRecordsSelection)}
                                            label='Show All'
                                        />
                                    </FormGroup>
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'outlined'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={() => setDeletedRecordsSelection(false)}
                                        variant={'outlined'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default FamilyMemberGridFilter
