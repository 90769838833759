import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { deleteSection } from 'app/redux/store/features/sections'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const SectionGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
    handleError,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const [sectionId, setSectionId] = useState(null)
    const dateTimeColumns = ['createTime', 'updateTime']
    const [sorting, setSorting] = useState([{ columnName: 'sectionName', direction: 'asc' }])
    const pageSize = 10
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    const {
        sections,
        loading: sectionLoading,
        error: sectionError,
        refreshing,
    } = useSelector((state) => state.sections)
    const { classConfig, loading: classLoading, error: classError } = useSelector((state) => state.classes)

    useEffect(() => {
        if (!apiCallInProgress || sectionLoading || classLoading) return
        setApiCallInProgress(false)
        const error = sectionError || classError
        if (error) handleError(error)
    }, [sectionLoading, classLoading])

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    useEffect(() => {
        if (sectionId) {
            navigate(`/courses/setup/classes/${classConfig.classId}/section/edit/${sectionId}`, {
                state: { classId: classConfig.classId, sectionId: sectionId },
            })
        }
    }, [sectionId, navigate])

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    setSectionId(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={sectionLoading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => setSectionId(row.id)}>
                    <EditIcon title='Edit row' sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveUser(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => setSectionId(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.sectionName}
            </Link>
        )
    }

    const renderTeacherCell = (row) => {
        const teacherName = row.teacherFirstName ? row.teacherFirstName + ' ' + row.teacherLastName : ''
        return teacherName
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (sectionError) {
            enqueueSnackbar(sectionError.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Section data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (id) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteSection({ classId: classConfig.classId, id: id }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'sectionName', title: 'Section Name', getCellValue: renderLinkCell },
        { name: 'level', title: 'Level' },
        { name: 'teacherFirstName', title: 'Teacher Name', getCellValue: renderTeacherCell },
        { name: 'capacity', title: 'Capacity' },
        { name: 'createTime', title: 'Create Time' },
        { name: 'updateTime', title: 'Updated Date' },
        { name: 'comments', title: 'Comments' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'sectionName', width: min ? '140px' : '14%', minWidth: '140px', wordWrapEnabled: true },
            { columnName: 'level', width: min ? '100px' : '10%', minWidth: '100px', align: 'center' },
            { columnName: 'teacherFirstName', width: min ? '130px' : '13%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'capacity', width: min ? '120px' : '12%', minWidth: '120px', align: 'center' },
            { columnName: 'createTime', width: min ? '120px' : '15%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'updateTime', width: min ? '120px' : '15%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'comments', width: min ? '150px' : '13%', minWidth: '150px', wordWrapEnabled: true },
            { columnName: 'Action', width: min ? '90px' : '8%', minWidth: '90px', align: 'center' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={sections.data} columns={columns}>
                    {sections.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <DateTimeTypeProvider for={dateTimeColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={sections.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default SectionGridData
