import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, PagingState, SortingState, SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { Paper, useMediaQuery } from '@mui/material'
import { getTermConfig, updateTermConfig } from 'app/redux/store/features/terms'
import { updateClassConfig } from 'app/redux/store/features/classes'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { makeStyles } from '@mui/styles'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const TermGridData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [sorting, setSorting] = useState([{ columnName: 'termYear', direction: 'asc' }])
    const pageSize = 25
    const { terms, termConfig, refreshing, error, loading } = useSelector((state) => state.terms)
    const booleanColumns = ['isCurrent']
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    useEffect(() => {
        // If term list is changed and we don't have current term id selected, then select the first row
        if (terms.data.length > 0) {
            if (!termConfig.termId) {
                dispatch(
                    updateTermConfig({
                        termId: terms.data[0].id,
                        termYear: terms.data[0].termYear,
                        termNumber: terms.data[0].termNumber,
                    })
                )
                dispatch(updateClassConfig({ classId: null }))
                setSelectedRowIds([terms.data[0].id])
            } else {
                setSelectedRowIds([termConfig.termId])
            }
        } else {
            dispatch(updateClassConfig({ classId: null }))
            dispatch(updateTermConfig({ termId: null, termYear: null, termNumber: null }))
            setSelectedRowIds([])
        }
    }, [terms])

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const useStyles = makeStyles((theme) => ({
        selectedRow: {
            backgroundColor: theme.palette.action.selected + ' !important',
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))
    const TableRow = ({ tableRow, selected, ...restProps }) => {
        const classes = useStyles()
        return (
            <TableSelection.Row
                {...restProps}
                onClick={() => {
                    dispatch(
                        updateClassConfig({
                            classId: null,
                        })
                    )
                    dispatch(
                        updateTermConfig({
                            termId: tableRow.row.id,
                            termYear: tableRow.row.termYear,
                            termNumber: tableRow.row.termNumber,
                        })
                    )
                    setSelectedRowIds([tableRow.row.id])
                }}
                selected={selectedRowIds.includes(tableRow.row.id)}
                className={selectedRowIds.includes(tableRow.row.id) ? classes.selectedRow : null}
            />
        )
    }

    const BooleanFormatter = ({ value }) => (value ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)
    const BooleanTypeProvider = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const onSelectionChange = (selectedRowIds) => {
        setSelectedRowIds(selectedRowIds)
    }

    const termCellRender = (data) => {
        return data.termYear + ' / ' + data.termNumber
    }

    const columns = [
        { name: 'isCurrent', title: ' ' },
        { name: 'termYear', title: 'Term', getCellValue: termCellRender },
        { name: 'termName', title: 'Term Name', wordWrapEnabled: true },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'isCurrent', width: min ? '60px' : '20%', minWidth: '60px', align: 'left' },
            { columnName: 'termYear', width: min ? '100px' : '35%', minWidth: '100px' },
            { columnName: 'termName', width: min ? '210px' : '45%', minWidth: '210px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={terms.data} columns={columns} rowSelection={'single'}>
                    {terms.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <BooleanTypeProvider for={booleanColumns} />
                    <SortingState sorting={sorting} onSortingChange={onSortingChange} columnSortingEnabled={true} />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={terms.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState selectedRowIds={selectedRowIds} onSelectionChange={onSelectionChange} />
                    <TableSelection
                        highlightRow={true}
                        selectByRowClick={true}
                        showSelectAll={false}
                        rowComponent={TableRow}
                        showSelectionColumn={false}
                        rowSelection={'single'}
                    />
                </Grid>
            }
        </Paper>
    )
}

export default TermGridData
