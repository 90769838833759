import React from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'

const ClassTypeGridFilter = ({ mutation, display, filterIconColor }) => {
    let initialValues = {
        classTypeName: '',
    }

    const onFormSubmit = (classTypeName) => {
        mutation.mutate({
            classTypeName: classTypeName,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.classTypeName)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0 }}>
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Class Type Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='classTypeName' />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'outlined'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'outlined'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}
export default ClassTypeGridFilter
