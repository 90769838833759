import React, { useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { getClassById, selectItemById } from 'app/redux/store/features/classes'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import UpdateClassForm from './UpdateClassForm'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import locationServices from 'app/services/pages/settings/definitions/locationServices/locationServices'
import teacherServices from 'app/services/pages/security/teacherServices/teacherServices'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import classTypeServices from 'app/services/pages/settings/definitions/classTypeServices/classTypeServices'
import classesService from '../../../../../../../../services/pages/courses/setup/classesServices/classesService'
import GenderTypeService from '../../../../../../../registrations/customers/components/GenderTypeService'
import ageGroupService from 'app/services/pages/settings/definitions/ageGroupServices/ageGroupServices'
import UpdateDetailImage from './UpdateDetailImage'
import UpdateSearchImage from './UpdateSearchImage'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery, Box } from '@mui/material'
import Div from '@jumbo/shared/Div'

const UpdateClass = () => {
    const [tabTitle, setTabTitle] = React.useState('update-class') // Initialize with a default value
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { termConfig } = useSelector((state) => state.terms)
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { classId } = state
    const { classes } = useSelector((state) => state.classes)
    const classData = selectItemById(classes, classId)
    const filePathSearchData = classData.filePathSearch
    const filePathDetailData = classData.filePathDetail
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    useEffect(() => {
        if (utilServices.isNullOrUndefined(classData.id)) {
            dispatch(getClassById({ id: classId }))
        }
    }, [])

    const onTabSelectionChanged = (e, newValue) => {
        setTabTitle(newValue)
    }

    const genderEditorOptions = {
        dataSource: GenderTypeService.getGenderType(),
        displayExpr: 'genderType',
        valueExpr: 'id',
        showClearButton: true,
    }

    const termLookupList = useCallback(async (searchTerm) => {
        try {
            return await termServices.getTermLookupList({ termYear: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupById = useCallback(async (key) => {
        try {
            return await termServices.getTermLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const toFullNames = useMemo(() => {
        return (data) => {
            return data ? data.termYear + ' / ' + data.termNumber : ''
        }
    }, [])

    const termEditorOptions = {
        dataSource: termLookupsStore,
        displayExpr: toFullNames,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: toFullNames,
    }

    const classTypeLookupList = useCallback(async (searchTerm) => {
        try {
            return await classTypeServices.getClassTypeLookupList({ classTypeName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const classTypeLookupById = useCallback(async (key) => {
        try {
            return await classTypeServices.getClassTypeLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const classTypeLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classTypeLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await classTypeLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const classTypeEditorOptions = {
        dataSource: classTypeLookupsStore,
        displayExpr: 'classTypeName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const locationLookupList = useCallback(async (searchTerm) => {
        try {
            return await locationServices.getLocationLookupList({ locationName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const locationLookupById = useCallback(async (key) => {
        try {
            return await locationServices.getLocationById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const locationLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await locationLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await locationLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const locationEditorOptions = {
        dataSource: locationLookupsStore,
        displayExpr: 'locationName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const classLookupList = useCallback(
        async (searchTerm) => {
            try {
                if (utilServices.isNullOrUndefined(termConfig?.termId)) return utilServices.emptyList()
                return await classesService.getPrvTermClassLookupList({
                    termId: termConfig.termId,
                    className: searchTerm,
                })
            } catch (error) {
                handleError(error)
            }
        },
        [termConfig.termId]
    )

    const classLookupById = useCallback(async (key) => {
        try {
            return await classesService.getClassLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const classLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await classLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await classLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const prevtoFullNames = useMemo(() => {
        return (data) => {
            return data ? data.termYear + '/' + data.termNumber + ', ' + data.className : ''
        }
    }, [])

    const prvClassEditorOptions = {
        dataSource: classLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: prevtoFullNames,
        displayExpr: prevtoFullNames,
    }

    const teacherLookupList = useCallback(async (searchTerm) => {
        try {
            return await teacherServices.getTeacherLookupList({ firstName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const teacherLookupById = useCallback(async (key) => {
        try {
            return await teacherServices.getTeacherById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const teacherLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await teacherLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await teacherLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const teacherFullName = useMemo(() => {
        return (data) => {
            return data ? data.firstName.toFullName(data.lastName) : ''
        }
    }, [])

    const teacherEditorOptions = {
        dataSource: teacherLookupsStore,
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
        itemTemplate: teacherFullName,
        displayExpr: teacherFullName,
        valueExpr: 'id',
    }

    const ageGroupLookupList = useCallback(async (searchTerm) => {
        try {
            return await ageGroupService.getAgeGroupLookupList({ ageGroupName: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ageGroupLookupById = useCallback(async (key) => {
        try {
            return await ageGroupService.getAgeGroupLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const ageGroupLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await ageGroupLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return utilServices.emptyList()

                        return await ageGroupLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const ageGroupEditorOptions = {
        dataSource: ageGroupLookupsStore,
        displayExpr: 'ageGroupName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Update Class Item
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <Tabs
                    value={tabTitle}
                    onChange={onTabSelectionChanged}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                    sx={{
                        mt: -0.7,
                        bgcolor: 'background.default',
                        '.MuiTabs-scrollButtons': {
                            // Styling for scroll buttons
                            '&.Mui-disabled': {
                                opacity: 0.3, // Ensure disabled buttons are visible but faded
                            },
                        },
                    }}>
                    <Tab
                        label='Update Class'
                        value='update-class'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Search Image'
                        value='search-image'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Detail Image'
                        value='detail-image'
                        sx={{ width: min ? '125px' : '160px', textTransform: 'capitalize' }}
                    />
                </Tabs>
                <Div>
                    {tabTitle === 'update-class' && (
                        <Box paddingLeft={2} paddingRight={2} paddingTop={2}>
                            <UpdateClassForm
                                classData={classData}
                                teacherEditorOptions={teacherEditorOptions}
                                classTypeEditorOptions={classTypeEditorOptions}
                                prvClassEditorOptions={prvClassEditorOptions}
                                locationEditorOptions={locationEditorOptions}
                                termEditorOptions={termEditorOptions}
                                genderEditorOptions={genderEditorOptions}
                                ageGroupEditorOptions={ageGroupEditorOptions}
                                handleError={handleError}
                            />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'search-image' && (
                        <Box paddingLeft={2} paddingRight={2}>
                            <UpdateSearchImage filePathSearchData={filePathSearchData} />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'detail-image' && (
                        <Box paddingLeft={2} paddingRight={2}>
                            <UpdateDetailImage filePathDetailData={filePathDetailData} />
                        </Box>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default UpdateClass
