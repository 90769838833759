import React from 'react'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboAppTextField from '@jumbo/components/JumboAppTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import dateFormat from 'dateformat'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const TransactionGridFilter = ({ mutation, display, filterIconColor }) => {
    let initialValues = {
        termId: '',
        txnNumber: '',
        startDate: DateUtils.getStartDate(30).toISOString().slice(0, 10),
        endDate: DateUtils.getEndDate().toISOString().slice(0, 10),
        termYear: null,
        termNumber: null,
    }

    const onFormSubmit = (termId, txnNumber, startDate, endDate, termYear, termNumber) => {
        mutation.mutate({
            txnNumber: txnNumber,
            startDate: startDate.toFilterStartDate(),
            endDate: endDate.toFilterEndDate(),
            termYear: termYear,
            termNumber: termNumber,
            termId: termId,
        })
    }

    const onFilterSubmit = (data, { setSubmitting }) => {
        setSubmitting(true)
        onFormSubmit(data.termId, data.txnNumber, data.startDate, data.endDate, data.termYear, data.termNumber)
        setSubmitting(false)
    }

    const handleClear = () => {
        filterIconColor(false)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onReset={onFilterSubmit}
            onSubmit={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0 }}>
                        <Div
                            className='grid-filter-parent'
                            style={{ display: 'flex', margin: '0% 1.5% 1.5% 1.5%', flexWrap: 'wrap' }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Start Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField
                                        style={{ width: 220 }}
                                        size='small'
                                        name='startDate'
                                        type='date'
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>End Date</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='endDate' type='date' />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Txn Number</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <JumboAppTextField style={{ width: 220 }} size='small' name='txnNumber' />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <LoadingButton
                                        size={'small'}
                                        type='submit'
                                        onClick={() => filterIconColor(true)}
                                        variant={'outlined'}
                                        loading={isSubmitting || mutation.isLoading}>
                                        <SearchIcon sx={{ fontSize: 18 }} /> Search
                                    </LoadingButton>
                                    <LoadingButton
                                        size={'small'}
                                        type={'reset'}
                                        onClick={handleClear}
                                        variant={'outlined'}>
                                        <AutorenewRoundedIcon sx={{ fontSize: 18 }} /> Clear
                                    </LoadingButton>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default TransactionGridFilter
