import React, { useEffect, useState } from 'react'
import Form, { ButtonItem, SimpleItem, ColCountByScreen, Label, GroupItem, EmptyItem } from 'devextreme-react/form'
import '../../../../../../../../constants/stylesheet.css'
import { useMediaQuery } from '@mui/material'

const ChangeSearchImageUpload = (props) => {
    const isSubmitDisabled = !!props.searchImage.filePath
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const submitButtonOptionsSearch = {
        text: 'Add',
        type: 'default',
        width: 120,
        icon: 'check',
        useSubmitBehavior: true,
        disabled: isSubmitDisabled,
    }

    const deleteButtonOptionsSearch = {
        text: 'Delete',
        type: 'danger',
        width: 120,
        icon: 'trash',
        useSubmitBehavior: false,
        onClick: props.onRemoveSearchImage,
        disabled: !props.filePathSearchData,
    }

    const validationRules = {
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        sortOrder: [{ type: 'required', message: 'Sort order is required.' }],
        file: [
            { type: 'required', message: 'Image is required.' },
            {
                type: 'custom',
                validationCallback: (e) => e.value[0]?.size <= 256 * 1024,
                message: 'Image should not be more than 256 KB.',
            },
        ],
    }

    return (
        <React.Fragment>
            <form action='' onSubmit={props.onAddSearchImage}>
                <Form
                    mode='form'
                    formData={props.searchImage}
                    showColonAfterLabel={false}
                    showValidationSummary={false}
                    labelLocation={labelLocation}>
                    <GroupItem cssClass='form-group-item'>
                        <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem />
                        <GroupItem cssClass='form-group-item' colSpan={6}>
                            <EmptyItem />
                            {!props.filePathSearchData || props.filePathSearchData === '' ? (
                                <SimpleItem
                                    cssClass='imageStyling'
                                    dataField='file'
                                    editorType='dxFileUploader'
                                    validationRules={validationRules.file}
                                    editorOptions={{
                                        uploadMode: 'useForm',
                                        multiple: 'false',
                                        selectButtonText: 'Select File',
                                        labelText: '',
                                        accept: 'image/*',
                                        allowedFileExtensions: ['.jpg', '.jpeg', '.png'],
                                        maxFileSize: 1024 * 512,
                                    }}>
                                    <Label text='Select an Image (800x1300px)' />
                                </SimpleItem>
                            ) : (
                                <SimpleItem>
                                    <div className='image-container'>
                                        <img src={props.filePathSearchData} alt='Preview' className='image-preview' />
                                    </div>
                                </SimpleItem>
                            )}
                        </GroupItem>
                    </GroupItem>
                    <EmptyItem />
                    <GroupItem>
                        <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                        <ButtonItem buttonOptions={submitButtonOptionsSearch} horizontalAlignment='right' />
                        <ButtonItem buttonOptions={deleteButtonOptionsSearch} horizontalAlignment='left' />
                    </GroupItem>
                </Form>
            </form>
        </React.Fragment>
    )
}

export default ChangeSearchImageUpload
